var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "grey lighten-4"
  }, [_c('div', {
    staticClass: "pa-4 pt-8 flex-shrink-0 white"
  }, [_c('div', {
    staticClass: "text-body-2 font-weight-black"
  }, [_vm._v(_vm._s(_vm.$t('MenuAtGlance')))]), _c('div', {
    staticClass: "text-h4 mt-4 font-weight-black"
  }, [_vm._v(" " + _vm._s(_vm.$t('SpecialForYou')) + " 💖 ")]), _vm.deliveryMethod ? _c('delivery-method-selector', {
    staticClass: "mt-8 mb-2",
    attrs: {
      "current-delivery-method": _vm.deliveryMethod
    },
    on: {
      "select": _vm.updateDeliveryMethod
    }
  }) : _vm._e(), _vm.deliveryMethod ? _c('current-address-display', {
    staticClass: "mb-4 mt-8",
    attrs: {
      "address-info": _vm.addressInfo,
      "is-pick-up": _vm.isPickUp
    },
    on: {
      "click": function ($event) {
        _vm.showAddressDialog = true;
      }
    }
  }) : _vm._e()], 1), _c('v-banner', {
    staticClass: "pa-0",
    staticStyle: {
      "top": "-1px !important",
      "box-shadow": "rgba(0,0,0,.11) 0 4px 4px"
    },
    attrs: {
      "single-line": "",
      "app": "",
      "color": "white",
      "sticky": ""
    }
  }, [!_vm.showSearch ? _c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-btn', {
    staticClass: "grey lighten-4",
    attrs: {
      "icon": "",
      "color": "black",
      "small": ""
    },
    on: {
      "click": _vm.showSearchField
    }
  }, [_c('v-icon', [_vm._v("mdi-magnify")])], 1), _c('div', {
    ref: "categoryTab",
    staticClass: "px-2 flex-grow-1 mr-2",
    staticStyle: {
      "align-items": "center",
      "display": "grid",
      "grid-auto-flow": "column",
      "grid-auto-columns": "min-content",
      "height": "36px",
      "grid-gap": "4px",
      "overflow-x": "scroll"
    }
  }, _vm._l(_vm.categoryWithDishesInfo, function (category) {
    return _c('div', {
      key: category.id,
      staticClass: "text-no-wrap mr-2 text-body-2 font-weight-black text-capitalize d-flex justify-center align-center",
      class: category.id === _vm.activeCategoryId ? 'black white--text' : '',
      staticStyle: {
        "border-radius": "50rem",
        "height": "32px",
        "padding": "5px 12px",
        "font-size": "14px !important"
      },
      attrs: {
        "id": 'navItem' + category.id
      },
      on: {
        "click": function ($event) {
          return _vm.gotoCategory(category);
        }
      }
    }, [_vm._v(" " + _vm._s(category.name) + " ")]);
  }), 0), _c('v-btn', {
    staticClass: "grey lighten-4",
    attrs: {
      "icon": "",
      "color": "black",
      "small": ""
    },
    on: {
      "click": function ($event) {
        _vm.expandAllCategories = true;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-dots-horizontal")])], 1)], 1) : _c('div', {
    staticClass: "d-flex"
  }, [_c('v-text-field', {
    staticClass: "ma-0",
    attrs: {
      "hide-details": "",
      "outlined": "",
      "autofocus": "",
      "placeholder": _vm.$t('SearchPlaceHolder'),
      "append-icon": "mdi-close"
    },
    on: {
      "input": _vm.showAllSearchDishes,
      "click:append": function ($event) {
        _vm.showSearch = false;
        _vm.searchWord = '';
      }
    },
    model: {
      value: _vm.searchWord,
      callback: function ($$v) {
        _vm.searchWord = $$v;
      },
      expression: "searchWord"
    }
  })], 1), !_vm.isPickUp && _vm.hasAddress ? _c('div', {
    staticClass: "mt-4",
    on: {
      "click": function ($event) {
        _vm.showDeliveryRuleInfo = true;
      }
    }
  }, [_c('div', {
    staticClass: "rounded",
    staticStyle: {
      "background-color": "#f0f0f0",
      "width": "100%"
    }
  }, [_c('v-progress-linear', {
    attrs: {
      "value": _vm.totalPriceWithoutMod / _vm.freePrice * 100,
      "color": "secondary",
      "height": "8",
      "buffer-value": _vm.currentBufferValue,
      "rounded": ""
    }
  })], 1), _c('div', {
    staticClass: "text-caption d-flex mt-2 black--text font-weight-black"
  }, [_c('div', [_vm.differentPrice < 0 ? [_vm._v(" " + _vm._s(_vm._f("priceDisplay")(-_vm.differentPrice)) + _vm._s(_vm.$t('MinimumPriceForDelivery')) + " ")] : _vm.differentFreePrice > 0 ? [_vm._v(" " + _vm._s(_vm._f("priceDisplay")(_vm.differentFreePrice)) + _vm._s(_vm.$t('TillYouReachedDeliveryFree')) + " ")] : _vm.differentFreePrice < 0 ? [_vm._v(" 🎉 " + _vm._s(_vm.$t('ReachedFreeDeliveryPrice')) + " ")] : _vm.canOrder ? [_vm._v(" " + _vm._s(_vm.$t('ReachedAndOrderNow')) + " ")] : _vm._e()], 2), _c('v-spacer'), _c('v-icon', {
    attrs: {
      "color": "black",
      "small": ""
    }
  }, [_vm._v("mdi-information-slab-circle-outline ")])], 1)]) : _vm._e()]), [!_vm.searchWord ? _c('div', {
    staticClass: "itemContainer pb-16"
  }, _vm._l(_vm.categoryWithDishesInfo, function (category) {
    return _c('div', {
      key: category.id
    }, [_c('div', {
      directives: [{
        name: "intersect",
        rawName: "v-intersect",
        value: {
          handler: function (e) {
            return _vm.onIntersect(e, category);
          },
          options: {}
        },
        expression: "{handler:e=>onIntersect(e,category),options:{\n        }}"
      }],
      attrs: {
        "id": 'category' + category.id
      }
    }), _c('div', {
      staticClass: "pa-4 mt-6"
    }, [_c('div', {
      staticClass: "text-h5 font-weight-black"
    }, [_vm._v(_vm._s(category.name))]), _c('div', {
      staticClass: "text-body-2"
    }, [_vm._v(_vm._s(category.desc))])]), _c('div', {
      staticStyle: {
        "display": "grid",
        "grid-template-columns": "repeat(auto-fill,minmax(300px,1fr))"
      }
    }, _vm._l(category.dishes, function (dish) {
      return _c('dish-card', {
        key: dish.id,
        attrs: {
          "count": _vm.dishCountInCart(dish.code),
          "image-root": _vm.GlobalConfig.imageRoot,
          "dish": dish
        },
        on: {
          "click": function ($event) {
            return _vm.dishClick(dish);
          },
          "detail": function ($event) {
            return _vm.dishClick(dish, true);
          }
        }
      });
    }), 1)]);
  }), 0) : _c('div', {
    staticClass: "itemContainer",
    staticStyle: {
      "min-height": "100vh"
    }
  }, [_vm.filteredDish.length > 0 ? _vm._l(_vm.filteredDish, function (dish) {
    return _c('dish-card', {
      key: dish.id,
      staticClass: "mt-2",
      attrs: {
        "count": _vm.dishCountInCart(dish.code),
        "image-root": _vm.GlobalConfig.imageRoot,
        "dish": dish
      },
      on: {
        "click": function ($event) {
          return _vm.dishClick(dish);
        },
        "detail": function ($event) {
          return _vm.dishClick(dish, true);
        }
      }
    });
  }) : [_c('div', {
    staticClass: "d-flex align-center justify-center flex-column pa-8 pt-16 mt-16"
  }, [_c('v-icon', {
    staticClass: "mt-n16",
    attrs: {
      "size": "64"
    }
  }, [_vm._v("mdi-text-search ")]), _c('div', {
    staticClass: "mt-4"
  }, [_vm._v(_vm._s(_vm.$t('NoSearchResults')))])], 1)]], 2)], !_vm.showCart ? _c('cart-button', {
    attrs: {
      "dish-count": _vm.dishCount,
      "total-price": _vm.totalPriceWithoutMod,
      "is-pick-up": _vm.isPickUp,
      "start-price": _vm.startPrice,
      "free-price": _vm.freePrice
    },
    on: {
      "click": function ($event) {
        _vm.showCart = true;
      }
    }
  }) : _vm._e(), _c('v-bottom-sheet', {
    attrs: {
      "scrollable": "",
      "fullscreen": ""
    },
    model: {
      value: _vm.showCart,
      callback: function ($$v) {
        _vm.showCart = $$v;
      },
      expression: "showCart"
    }
  }, [_c('v-card', {
    staticStyle: {
      "overflow": "scroll"
    },
    attrs: {
      "tile": ""
    }
  }, [_c('div', {
    staticClass: "pa-4 pt-8"
  }, [_c('v-btn', {
    staticClass: "ml-n2",
    attrs: {
      "icon": "",
      "large": "",
      "color": "black"
    },
    on: {
      "click": function ($event) {
        _vm.showCart = false;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-arrow-left")])], 1), _c('div', {
    staticClass: "text-h4 font-weight-black"
  }, [_vm._v(" " + _vm._s(_vm.$t('ShoppingCart')) + " ")]), _c('div', {
    staticClass: "mt-8"
  }, [_c('div', {
    staticClass: "text-body-2 font-weight-black"
  }, [_vm._v(_vm._s(_vm.$t('IncludeVat')))]), _c('div', {
    staticClass: "mt-2 text-h4 font-weight-black"
  }, [_vm._v(" " + _vm._s(_vm._f("priceDisplay")(_vm.totalPriceWithoutMod)) + " ")])]), _c('div', {
    staticClass: "pb-16 mt-12"
  }, [_c('div', {
    staticClass: "d-flex text-body-2 font-weight-black"
  }, [_vm._v(" # "), _c('div', {
    staticClass: "ml-6"
  }, [_vm._v(_vm._s(_vm.$t('Items')))])]), _vm._l(_vm.cartList, function (dish) {
    var _ref, _dish$dishName;
    return _c('v-card', {
      key: dish.id,
      staticClass: "d-flex mb-4 text-body-1 align-baseline",
      staticStyle: {
        "border-radius": "12px"
      },
      attrs: {
        "elevation": "0"
      }
    }, [_c('div', {
      staticClass: "font-weight-black"
    }, [_vm._v(_vm._s(dish.count) + "×")]), _c('div', {
      staticClass: "mx-3 flex-grow-1"
    }, [_c('div', [_vm._v(_vm._s((_ref = (_dish$dishName = dish === null || dish === void 0 ? void 0 : dish.dishName) !== null && _dish$dishName !== void 0 ? _dish$dishName : dish === null || dish === void 0 ? void 0 : dish.name) !== null && _ref !== void 0 ? _ref : ''))]), dish.displayApply.length > 0 ? _c('div', {
      staticClass: "text-body-2 text--secondary mt-1"
    }, [_vm._v(" " + _vm._s(dish.displayApply.map(function (it) {
      return it.value;
    }).join(', ')) + " ")]) : _vm._e(), dish.note ? _c('div', {
      staticClass: "text-body-2 text--secondary mt-1"
    }, [_vm._v(" # " + _vm._s(dish.note) + " ")]) : _vm._e()]), _c('div', {
      staticClass: "font-weight-black mr-2 text-no-wrap"
    }, [_vm._v(" " + _vm._s(_vm._f("priceDisplay")(dish.realPrice)) + " ")]), _c('div', {
      staticClass: "d-flex align-center",
      staticStyle: {
        "border-radius": "24px"
      }
    }, [_c('v-card', {
      staticClass: "pa-1",
      staticStyle: {
        "border-radius": "24px"
      },
      attrs: {
        "color": "grey lighten-2",
        "elevation": "0"
      },
      on: {
        "click": function ($event) {
          return _vm.editDishNote(dish);
        }
      }
    }, [_c('v-icon', [_vm._v("mdi-note-edit")])], 1), _c('v-card', {
      staticClass: "pa-1",
      staticStyle: {
        "border-radius": "24px"
      },
      attrs: {
        "color": "grey lighten-2",
        "elevation": "0"
      },
      on: {
        "click": function ($event) {
          return dish.change(-1);
        }
      }
    }, [_c('v-icon', [_vm._v("mdi-minus")])], 1), _c('v-card', {
      staticClass: "pa-1",
      staticStyle: {
        "border-radius": "24px"
      },
      attrs: {
        "color": "grey lighten-1",
        "elevation": "0"
      },
      on: {
        "click": function ($event) {
          return dish.change(1);
        }
      }
    }, [_c('v-icon', [_vm._v("mdi-plus")])], 1)], 1)]);
  })], 2), _c('cart-button', {
    staticStyle: {
      "bottom": "12px !important"
    },
    attrs: {
      "dish-count": _vm.dishCount,
      "total-price": _vm.totalPriceWithoutMod,
      "is-pick-up": _vm.isPickUp,
      "start-price": _vm.startPrice,
      "free-price": _vm.freePrice,
      "disable": !_vm.canOrder,
      "restaurant-is-open": _vm.restaurantIsOpen
    },
    on: {
      "click": function ($event) {
        return _vm.$router.push('/checkout');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('ToCashOut')) + " ")])], 1)])], 1), _c('address-selector', {
    attrs: {
      "init-delivery-method": _vm.deliveryMethod
    },
    on: {
      "save": _vm.save
    },
    model: {
      value: _vm.showAddressDialog,
      callback: function ($$v) {
        _vm.showAddressDialog = $$v;
      },
      expression: "showAddressDialog"
    }
  }), _c('dish-detail-sheet', {
    attrs: {
      "show": _vm.showDishDetailSheet,
      "image-root": _vm.GlobalConfig.imageRoot,
      "dish": _vm.currentDish
    },
    on: {
      "modification-cancel": _vm.cancelEdit,
      "modification-submit": _vm.submitModification
    }
  }), _c('restaurant-detail-page', {
    attrs: {
      "show": _vm.showRestaurantInfo,
      "opening-time-list": _vm.openingTimeList,
      "is-open": _vm.isOpen,
      "today-open-time-display": _vm.todayOpenTimeDisplay,
      "restaurant-info": _vm.restaurantInfo
    },
    on: {
      "close": function ($event) {
        _vm.showRestaurantInfo = false;
      }
    }
  }), _c('v-dialog', {
    attrs: {
      "fullscreen": ""
    },
    model: {
      value: _vm.expandAllCategories,
      callback: function ($$v) {
        _vm.expandAllCategories = $$v;
      },
      expression: "expandAllCategories"
    }
  }, [_c('v-card', {
    attrs: {
      "tile": ""
    }
  }, [_c('div', [_c('div', {
    staticClass: "d-flex align-center px-4 py-3 grey lighten-4"
  }, [_c('div', {
    staticClass: "text-h6 font-weight-black"
  }, [_vm._v(" " + _vm._s(_vm.$t("Category")) + " ")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function ($event) {
        _vm.expandAllCategories = false;
      }
    }
  }, [_c('v-icon', [_vm._v(" mdi-close ")])], 1)], 1), _c('div', {
    staticClass: "mt-2 px-4 pb-4"
  }, _vm._l(_vm.categoryWithDishesInfo, function (category) {
    return _c('div', {
      key: category.id,
      staticClass: "mb-4 d-flex",
      on: {
        "click": function ($event) {
          _vm.gotoCategory(category);
          _vm.expandAllCategories = false;
        }
      }
    }, [_c('div', [_c('div', {
      staticClass: "text-body-1 font-weight-black"
    }, [_vm._v(" " + _vm._s(category.name) + " ")]), _c('div', {
      staticClass: "text-body-2 text--secondary"
    }, [_vm._v(" " + _vm._s(category.desc || 'No description') + " ")])]), _c('v-spacer'), _c('v-avatar', {
      attrs: {
        "size": "36"
      }
    }, [_c('v-img', {
      attrs: {
        "src": _vm.GlobalConfig.resourceRoot + category.dcImage
      }
    })], 1)], 1);
  }), 0)])])], 1), _c('v-dialog', {
    attrs: {
      "fullscreen": ""
    },
    model: {
      value: _vm.showCloseDialog,
      callback: function ($$v) {
        _vm.showCloseDialog = $$v;
      },
      expression: "showCloseDialog"
    }
  }, [_c('v-card', {
    attrs: {
      "tile": ""
    }
  }, [_c('v-fade-transition', [_c('div', {
    staticClass: "pa-4 text-center d-flex flex-column align-center justify-center"
  }, [_c('div', {
    staticStyle: {
      "height": "45vh"
    }
  }, [_c('lottie-animation', {
    ref: "animation",
    staticStyle: {
      "height": "60vh"
    },
    attrs: {
      "animationData": require('@/assets/closed.json')
    }
  })], 1), _c('div', {
    staticClass: "text-h4 font-weight-black"
  }, [_vm._v(_vm._s(_vm.$t('Sorry')))]), _c('div', {
    staticClass: "my-4 text-body-1"
  }, [_vm._v(_vm._s(_vm.$t('CurrentlyNotAcceptingOrders')) + " "), _c('span', [_vm._v("☎️")]), _vm._v("️ "), _c('a', {
    staticClass: "ml-2",
    attrs: {
      "href": 'tel://' + _vm.restaurantInfo.telephone
    }
  }, [_vm._v(" " + _vm._s(_vm.restaurantInfo.telephone))])]), _c('v-btn', {
    staticClass: "pa-2 mb-4",
    attrs: {
      "elevation": "0",
      "color": "grey lighten-4"
    },
    on: {
      "click": function ($event) {
        _vm.openTimeDialog = true;
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('showOpeningTimes')) + " ")]), _c('v-card', {
    staticClass: "pa-4 d-flex align-center justify-center text-body-1 font-weight-black",
    staticStyle: {
      "position": "fixed",
      "bottom": "0",
      "left": "0",
      "right": "0",
      "width": "100vw"
    },
    attrs: {
      "flat": "",
      "tile": "",
      "color": "grey lighten-4"
    },
    on: {
      "click": function ($event) {
        _vm.showCloseDialog = false;
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-check")]), _vm._v(" " + _vm._s(_vm.$t('Confirm')) + " ")], 1)], 1)])], 1)], 1), _c('v-dialog', {
    model: {
      value: _vm.openTimeDialog,
      callback: function ($$v) {
        _vm.openTimeDialog = $$v;
      },
      expression: "openTimeDialog"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "text-h5 lighten-2"
  }, [_vm._v(" " + _vm._s(_vm.$t('openingTimes')) + " ")]), _c('v-divider'), _c('v-card-text', [_c('div', {
    staticClass: "mt-2"
  }, _vm._l(_vm.openingTimeList, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "d-flex mt-1 justify-space-between"
    }, [_c('div', {
      staticClass: "font-weight-bold"
    }, [_vm._v(" " + _vm._s(item.weekday) + " ")]), _c('div', {
      staticClass: "text-right"
    }, _vm._l(item.openTime, function (timeItemForAll, n) {
      return _c('span', {
        key: n
      }, [_vm._v(" " + _vm._s(timeItemForAll.join(' - '))), _c('br')]);
    }), 0)]);
  }), 0)])], 1)], 1), _c('v-dialog', {
    model: {
      value: _vm.showNoteDialog,
      callback: function ($$v) {
        _vm.showNoteDialog = $$v;
      },
      expression: "showNoteDialog"
    }
  }, [_c('v-card', {
    staticClass: "pa-4",
    attrs: {
      "width": "400px"
    }
  }, [_c('div', {
    staticClass: "text-h4 font-weight-black"
  }, [_vm._v(_vm._s(_vm.$t('AddNote')))]), _c('v-textarea', {
    staticClass: "mt-4",
    attrs: {
      "placeholder": _vm.$t('FurtherDeliveryInstructions'),
      "outlined": ""
    },
    model: {
      value: _vm.noteInput,
      callback: function ($$v) {
        _vm.noteInput = $$v;
      },
      expression: "noteInput"
    }
  }), _c('v-btn', {
    staticClass: "text-capitalize text-body-2 font-weight-black",
    attrs: {
      "block": "",
      "large": "",
      "elevation": "0",
      "color": "black white--text"
    },
    on: {
      "click": _vm.saveDishNote
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('Save')) + " ")])], 1)], 1), !_vm.showBackToTop ? _c('v-btn', {
    style: _vm.dishCount > 0 ? {
      bottom: '144px'
    } : {
      bottom: '96px'
    },
    attrs: {
      "right": "",
      "bottom": "",
      "fixed": "",
      "fab": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$vuetify.goTo(0);
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-arrow-up")])], 1) : _vm._e(), _c('v-bottom-sheet', {
    attrs: {
      "dark": ""
    },
    model: {
      value: _vm.showDeliveryRuleInfo,
      callback: function ($$v) {
        _vm.showDeliveryRuleInfo = $$v;
      },
      expression: "showDeliveryRuleInfo"
    }
  }, [_vm.showDeliveryRuleInfo ? _c('v-card', {
    staticClass: "pa-4"
  }, [_c('div', {
    staticClass: "text-h4"
  }, [_vm._v(" 📋 ")]), _c('div', {
    staticClass: "text-body-1 font-weight-black"
  }, [_vm._v(_vm._s(_vm.$t('DeliveryRules')))]), _c('div', {
    staticClass: "text-body-2 font-weight-black mt-4"
  }, [_c('div', [_vm._v(" " + _vm._s(_vm.$t('MinimumOrderValue')) + ": " + _vm._s(_vm._f("priceDisplay")(_vm.startPrice)) + " ")]), _c('div', [_vm._v(" " + _vm._s(_vm.$t('FreeDeliveryFrom')) + " " + _vm._s(_vm._f("priceDisplay")(_vm.freePrice)) + " ")]), _c('div', [_vm._v(" " + _vm._s(_vm.$t('DeliveryCost')) + ": " + _vm._s(_vm._f("priceDisplay")(_vm.deliveryCost)) + " ")])]), _c('v-btn', {
    staticClass: "mt-4 text-body-1 font-weight-black",
    attrs: {
      "large": "",
      "block": "",
      "light": "",
      "color": "white"
    },
    on: {
      "click": function ($event) {
        _vm.showDeliveryRuleInfo = false;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('Confirm')) + " ")])], 1) : _vm._e()], 1)], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }