<template>
  <v-card
      tile
      height="calc(100vh - 64px)"
      style="overflow-y: scroll"
      dark
      class="black lighten-4 pb-4 d-flex flex-column"
  >
    <div class="pa-4 pt-8 flex-shrink-0">
      <div class="text-body-2 font-weight-black ">
        🌈 {{ $t('WelcomeTo') }}
      </div>
      <div class="text-h4 mt-4 font-weight-black d-block overflow-hidden">
        <div>{{ restaurantInfo.name }}</div>
      </div>
    </div>
    <div
        class="mt-2 px-4 flex-shrink-0"
    >

      <div>
        <div
            class="text-caption font-weight-black pink--text
         text--lighten-4"
        >
          {{ $t('openingTimes') }}
        </div>
        <div class="text-body-2 mt-1 font-weight-black">
          <span class=" "> {{ todayOpenTimeDisplay }}</span>
        </div>
      </div>
    </div>
    <div class="mt-2 px-4 flex-shrink-0">
      <div class="text-body-2">
        <v-icon
            small
            color="#D0EF27"
        >mdi-store
        </v-icon>
        <v-icon
            small
            color="amber lighten-4"
        >mdi-emoticon
        </v-icon>
        <v-icon
            small
            color="pink lighten-4"
        >mdi-map-marker
        </v-icon>
      </div>
      <div class="text-body-2 mt-1 font-weight-black">
          <span class=" ">
            {{ restaurantInfo.adress1 }}
            {{ restaurantInfo.postCode }}
            {{ restaurantInfo.city }}
          </span>
      </div>
    </div>
    <div class="px-4 mt-4">
      <v-card
          @click="showAnnoucementDialog=true"
          flat
          dark
          class="pa-4 d-flex"
      >
        <div
            class="text-body-2 font-weight-black text-no-wrap text-truncate flex-grow-1"
            style="width: 0px"
        >
          <v-icon
              small
              class="mr-2"
          >mdi-bell
          </v-icon>
          {{ restaurantInfo.takeawayAnnouncementHead ? restaurantInfo.takeawayAnnouncementHead : restaurantInfo.buffetAnnouncementHead }}
        </div>
        <v-spacer></v-spacer>
        <v-icon small>mdi-arrow-right</v-icon>
      </v-card>
    </div>
    <div class="d-flex mx-4 mt-8 align-center">
      <div class=" font-weight-black text-body-2  ">
        💖 {{ $t('SelectYourFavorite') }}
      </div>
    </div>

    <div
        class="pa-4 flex-shrink-0"
        style="display: grid;grid-template-columns: repeat(2,minmax(0,1fr));grid-gap: 12px"
    >
      <v-card
          :disabled="!GlobalConfig.delivery"
          :light="GlobalConfig.delivery"
          class="pa-2 px-4 d-flex align-center"
          @click="startToOrder(DeliveryMethod.delivery)"
          :dark="!GlobalConfig.delivery"
          :color="GlobalConfig.delivery?'pink lighten-5':'grey darken-4'"
      >
        <v-card
            flat
            color="transparent"
            class="pa-1 mr-2"
        >
          <v-icon
              :color="GlobalConfig.selfPickUp?'black':'white'"
          >mdi-truck
          </v-icon>
        </v-card>
        <div class="text-body-2 font-weight-black nippo text-no-wrap text-truncate">
          {{ $t(DeliveryMethod.delivery) }}
        </div>
      </v-card>
      <v-card
          :disabled="!GlobalConfig.selfPickUp"
          class="pa-2 px-4 d-flex align-center"
          @click="startToOrder(DeliveryMethod.pickup)"
          :dark="!GlobalConfig.selfPickUp"
          :light="GlobalConfig.selfPickUp"
          :color="GlobalConfig.selfPickUp?'amber lighten-5':'grey darken-4'"
      >
        <v-card
            flat
            color="transparent"
            class="pa-1 mr-2"
        >
          <v-icon
              :color="GlobalConfig.selfPickUp?'black':'white'"
          >mdi-shopping
          </v-icon>
        </v-card>
        <div class="text-body-2 font-weight-black nippo text-no-wrap text-truncate">
          {{ $t(DeliveryMethod.pickup) }}
        </div>
      </v-card>
    </div>

    <div class="d-flex mx-4 mt-4 align-center">
      <div class=" font-weight-black text-body-2">
        🥳 {{ $t('UpdateFeed') }}
      </div>
      <v-spacer></v-spacer>
    </div>
    <div
        class="px-4 pt-4"
        style="display: grid;grid-template-columns: repeat(auto-fill,minmax(300px,1fr)); grid-gap: 12px;"
    >
      <v-card

          @click="showPostDetail(p.id)"
          v-for="p in postList"
          class="d-flex align-start py-4 px-4"
          color="surface"
          :key="p.id"
      >
        <div
            class="flex-grow-1 mr-4 d-flex flex-column"
            style="width: 0;height: 100%"
        >
          <div class="text-body-1 font-weight-black text">
            {{ p.content }}
          </div>
          <v-spacer></v-spacer>
          <div

              class="text-caption d-flex align-center mt-1"
          >
            <v-card
                elevation="0"
                @click.stop="likePost(p.id)"
                class="px-2 d-flex align-center grey darken-3"
            >
              <v-icon
                  color="pink"
                  small
                  class="mr-1"
              >mdi-heart
              </v-icon>
              {{ p.like }}
            </v-card>

            <v-icon>mdi-circle-small</v-icon>
            {{ p.createdAt | timeToNow }}


          </div>
        </div>
        <v-card rounded="lg">
          <v-img
              height="72"
              width="108"
              :src="p.imageRealUrl"
          ></v-img>
        </v-card>

      </v-card>
    </div>


    <v-bottom-sheet
        v-model="showPostDialog"
    >
      <v-card
          tile
          color="grey lighten-4"
          width="100vw"
          v-if="activePost"
      >
        <div class="pa-6">
          <div class="text-h4 font-weight-black">{{ restaurantInfo.name }}</div>
          <div class="text-body-2 mt-2 font-weight-black">
            {{ $t('Published') }}: {{ activePost.createdAt |timeToNow }}
          </div>
        </div>
        <div
            class="mb-16"
            style="max-height: calc(90vh - 200px);overflow-y: scroll"
        >
          <v-card
              elevation="0"
              v-if="activePost?.imageRealUrl"
              class="pa-6"
          >
            <v-img
                max-width="600"
                :src="activePost?.imageRealUrl"
                width="100%"
            ></v-img>
          </v-card>
          <v-card
              elevation="0"
              class="px-6 pb-6 pt-6 pb-16"
              style="white-space: pre-line"
          >
            {{ activePost.content }}
          </v-card>
        </div>
        <v-card
            style="position: fixed;bottom: 0;left: 0;right: 0;"
            tile

            color="grey lighten-4"
            class="pa-4 px-6 d-flex  text-body-1 font-weight-black"
        >
          <div class="d-flex mr-4 align-center">
            <v-card
                @click.stop="likePost(activePostId)"
                flat
                rounded="xl"
                color="pink lighten-5"
                class="pa-2 d-flex mr-2 align-center"
            >
              <v-icon
                  color="pink"
              >mdi-heart
              </v-icon>
            </v-card>
            {{ activePost?.like ?? 0 }}
          </div>
          <v-spacer></v-spacer>
          <v-icon @click="showPostDialog=false">mdi-close</v-icon>
        </v-card>

      </v-card>
    </v-bottom-sheet>
    <v-bottom-sheet
        v-model="showAnnoucementDialog"
    >
      <v-card
          tile
          color="grey lighten-4"
          width="100vw"
      >
        <div class="pa-6">
          <div class="text-h4 font-weight-black">{{ restaurantInfo.name }}</div>
        </div>
        <div
            class="mb-16"
            style="max-height: calc(90vh - 206px);overflow-y: scroll"
        >
          <v-card
              elevation="0"
              class="px-6 pt-6 pb-16 text-body-2"
              style="white-space: pre-line"
          >
            <div class="text-body-1 font-weight-black">
              {{ restaurantInfo.takeawayAnnouncementHead ? restaurantInfo.takeawayAnnouncementHead : restaurantInfo.buffetAnnouncementHead }}
            </div>
            <div>
              {{ restaurantInfo.takeawayAnnouncementBody ? restaurantInfo.takeawayAnnouncementBody : restaurantInfo.buffetAnnouncementBody  }}
            </div>


          </v-card>
        </div>
        <v-card
            @click="showAnnoucementDialog=false"
            style="position: fixed;bottom: 0;left: 0;right: 0;height: 64px"
            tile
            class="pa-4 d-flex align-center justify-center text-body-1 font-weight-black"
            color="grey lighten-4"
        >
          <v-icon
              class="mr-2"
          >mdi-check
          </v-icon>
          {{ $t('OK') }}
        </v-card>

      </v-card>
    </v-bottom-sheet>

    <address-selector
        @save="save"
        v-model="showAddressDialog"
    />
    <restaurant-detail-page
        @close="showRestaurantInfo=false"
        :show="showRestaurantInfo"
        :opening-time-list="openingTimeList"
        :is-open="isOpen"
        :today-open-time-display="todayOpenTimeDisplay"
        :restaurant-info="restaurantInfo"
    >
    </restaurant-detail-page>
  </v-card>
</template>

<script>
import {getOpeningTime, getWeekDay, isInOpenTime} from '@/dataLayer/service/api'
import {
  defaultRestaurantInfo,
  getActivityPosts,
  getCurrentRestaurantInfo,
  likePost
} from '@/dataLayer/repository/restaurantInfo'
import RestaurantDetailPage from "@/components/fragment/RestaurantDetailPage"
import dayjs from "dayjs"
import GlobalConfig from "@/dataLayer/repository/GlobalSettingManager"
import i18n from "@/i18n"
import AddressSelector from "@/pages/widget/adress/AddressSelector"
import {getOrderStatusByCTSId, OrderInfo} from '@/dataLayer/repository/currentOrderState'
import {DeliveryMethod} from '@/dataLayer/service/OrderService'

export default {
  name: "HomePage",
  components: {
    AddressSelector,
    RestaurantDetailPage,
  },
  data: function () {
    return {
      showAddressDialog: false,
      imgUrl: GlobalConfig.bannerPath ?? 'Resource/Image/banner.png',
      recommendedDishes: [],
      showRestaurantInfo: false,
      showAnnoucementDialog: false,
      restaurantInfo: defaultRestaurantInfo,

      openingTimeList: [],
      todayOpenTime: {},
      isOpen: false,
      restaurantIsOpen: true,
      GlobalConfig,
      showBackToTop: true,

      postList: [],
      lastOrder: null,
      activePostId: null,
      showPostDialog: false,
    }
  },
  computed: {
    DeliveryMethod() {
      return DeliveryMethod
    },

    activePost() {
      return this.postList.find(it => it.id === this.activePostId)
    },
    todayOpenTimeDisplay: function () {
      return this.todayOpenTime?.openTime?.map(a => a.join('-')).join(', ') ?? i18n.t("TodayClosed")
    },

  },
  methods: {
    getOrderStatusByCTSId,
    async likePost(postId) {
      await likePost(postId)
      this.postList = await getActivityPosts()
    },
    startToOrder(deliveryMethod) {
      OrderInfo.deliveryMethod = deliveryMethod
      this.$router.push('/menu')
    },
    save(addressInfo) {
      OrderInfo.address = addressInfo
      if (addressInfo) {
        OrderInfo.deliveryRule = addressInfo.deliveryRule
        OrderInfo.deliveryMethod = addressInfo.deliveryMethod
      }
      this.showAddressDialog = false
      this.$router.push('/menu')
    },
    showPostDetail(id) {
      this.activePostId = id
      this.showPostDialog = true
    },
  },
  async mounted() {
    this.restaurantInfo = await getCurrentRestaurantInfo()
    this.openingTimeList = await getOpeningTime()
    this.todayOpenTime = (this.openingTimeList).find(item => parseInt(item.id) === parseInt(getWeekDay()))
    this.isOpen = isInOpenTime(dayjs().format('HH:mm'), this.todayOpenTime?.openTime)
    this.restaurantIsOpen = (this.restaurantInfo?.currentlyOpening ?? true)
    this.postList = await getActivityPosts()
    if (!this.restaurantIsOpen) {
      setTimeout(() => {
        this.show = true
      }, 1000)
    }
  }
}
</script>
<style>
::-webkit-scrollbar {
  display: none;
}

.v-application .nippo {
  font-family: 'Nippo', serif !important;
}

.gradient-text {
  background: hsla(339, 100%, 55%, 1);
  background: linear-gradient(0deg, #ff930f 0%, #45caff 100%);
  background: -moz-linear-gradient(0deg, #ff930f 0%, #45caff 100%);
  background: -webkit-linear-gradient(0deg, #ff930f 0%, #45caff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.v-card {
  user-select: none;
}

.text {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
}
</style>


